import axios from 'axios';
import { API_URL } from '../config';
import querystring from 'querystring';

export function fetchBulks(boardId, token, filters = null) {
  let query = filters
    ? {
        boardId,
        offset: filters.offset,
        limit: filters.limit,
      }
    : {
        boardId,
      };
  const queryString = querystring.stringify(query);
  return axios.get(`${API_URL}/audits-bulks?${queryString}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export function fetchBulksUrl(url, token, filters = null) {
  if (filters) url = url.replace(/limit=[0-9]*/i, `limit=${filters.limit}`);
  return axios.get(`${API_URL}${url}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export function fetchBulkLicenses(bulkId, token, filters = null) {
  let query = filters ? { offset: filters.offset, limit: filters.limit } : {};
  const queryString = querystring.stringify(query);
  return axios.get(`${API_URL}/audits-bulks/${bulkId}/licenses?${queryString}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export function fetchBulkLicensesUrl(url, token, filters = null) {
  if (filters) url = url.replace(/limit=[0-9]*/i, `limit=${filters.limit}`);
  return axios.get(`${API_URL}${url}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export function confirmBulk(bulkId, token) {
  return axios.post(`${API_URL}/audits-bulks/${bulkId}/process`, {}, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export function deleteBulk(bulkId, token) {
  return axios.delete(`${API_URL}/audits-bulks/${bulkId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export function stopBulk(bulkId, token) {
  return axios.post(`${API_URL}/audits-bulks/${bulkId}/stop`, {}, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export function deleteBulkLicense(bulkId, bulkLicenseId, token) {
  return axios.delete(`${API_URL}/audits-bulks/${bulkId}/licenses/${bulkLicenseId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export function archiveBulkLicense(bulkId, bulkLicenseId, token) {
  return axios.request({
    method: 'put',
    url: `${API_URL}/audits-bulks/${bulkId}/licenses/${bulkLicenseId}/archive`,
    headers: { authorization: `Bearer ${token}` },
  });
}
